
const anmatiedScrollObserver = new IntersectionObserver((entries) => {
    entries.forEach((entity) => {
        if (entity.isIntersecting) {
            entity.target.classList.add('enter')
            anmatiedScrollObserver.unobserve(entity.target)
        }
    })
})

export default {
    bind(el) {
        el.classList.add('before-enter')
        anmatiedScrollObserver.observe(el)
    }
}