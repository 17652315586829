

axios.defaults.baseURL = 'https://api.sm-developer.dev/api/';
import axios from "axios";

const setup = () => {
    axios.interceptors.request.use(
        (config) => {
            if (config.url != "https://discord.com/api/users/@me") {
                const token = sessionStorage.getItem('sm_storage_token');
                if (token) {
                    config.headers.Authorization = token; // for Node.js Express back-end
                }
                return config;
            } else {
                return config;
            }
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    axios.interceptors.response.use(
        (res) => {
            return res;
        },
        async (err) => {
            const originalConfig = err.config;
            if (originalConfig.url !== "/auth/discord/redirect" && err.response) {
                // Access Token was expired
                if (err.response.status === 401 && !originalConfig._retry) {
                    originalConfig._retry = true;

                    try {
                        const user = JSON.parse(sessionStorage.getItem('sm_storage'));
                        const { data } = await axios.post('tokenRefresh', {
                            discordid: user.discordId,
                            refreshToken: sessionStorage.getItem('sm_storage_refreshToken'),
                        });
                        const accessToken = data.token;

                        sessionStorage.setItem('sm_storage_token', accessToken)
                        return axios(originalConfig)
                    } catch (_error) {
                        return Promise.reject(_error);
                    }
                }
            }

            return Promise.reject(err);
        }
    );
};

export default setup;
