<template>

    <div id="app">
        <NotificationC></NotificationC>
        <navBar v-if="this.$route.name != 'Login'" :navBarSet="navBarSet" @update-page-selected="onUpdatePropPageSelected"></navBar>
        <router-link to="/"></router-link>
        <router-link to="/login"></router-link>
        <router-link to="/script/:name"></router-link>
        
    </div>
    <router-view/>
    
</template>

<script>
import './assets/tailwind.css'
import loadingScreen from './components/loadingScreen.vue'
import navBar from './components/navBar.vue'
import NotificationC from './components/Notificationx.vue'

export default {
    name: 'App',
    components: {
        loadingScreen,
        navBar,
        NotificationC
    },
    data() {
        return {
            navBarSet: {
                onPage: this.$route.name,
                menu: [
                    { name: 'Home', link: '/', label: 'Home' },
                    { name: 'Shop', link: '/shop', label: 'Product' },
                    { name: 'About', link: '/about', label: 'Terms & Condition' },
                    { name: 'Docs', label: 'Docs'},
                ],
            },
        }
    },
    methods: {
        onUpdatePropPageSelected(link) {
            console.log(link);
            this.$router.push(link) 
        }
    }
}
</script>

<style>
html, 
body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    /* background: linear-gradient(180deg, #000428 0%, #004E92 100%); */
    background-color: #12131A;
    
}
.btn {
    background: linear-gradient(90.46deg, rgba(0, 150, 255, 0.8) 0.4%, rgba(0, 124, 211, 0.72) 99.6%);
}
.textHover {
    color: rgba(255, 255, 255, 0.7);
    transition: all .5s;
}
.textHover:hover {
    color: rgba(255, 255, 255, 1);
}


</style>
