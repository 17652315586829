import { createApp } from 'vue'
import App from './App.vue'
import './assets/tailwind.css'
import router from './router'
import scrollanimation from './components/scrollanimation'
import VueCookies from 'vue-cookies';
// import Notifications from '@kyvg/vue3-notification'
import Notifications from 'notiwind'

import setupInterceptors from './interceptors/axios';
setupInterceptors();


createApp(App)
.directive('scrollanimation', scrollanimation)
.use(router)
.use(VueCookies)
.use(Notifications)
.mount('#app')
