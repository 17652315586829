import {createRouter, createWebHistory} from 'vue-router'
import NotFoundComponent from '../views/pageNotfound.vue'

const routes = [
    {
        path: '/', 
        name: "Home",
        component: () => import('../views/home.vue'),
        meta: { title: 'SM Developer' }
    },
    {
        path: '/shop', 
        name: "Shop",
        component: () => import('../views/shop.vue'),
        meta: { title: 'SM Developer' }
    },
    {
        path: '/login', 
        name: "Login",
        component: () => import('../views/login.vue'),
        meta: { title: 'SM Developer' }
    },
    {
        path: '/scripts/:name', 
        name: "Script",
        component: () => import('../views/script.vue'),
        meta: { title: 'SM Developer' },
        props: true
    },
    {
        path: '/contact', 
        name: "Contact",
        component: () => import('../views/contact.vue'),
        meta: { title: 'SM Developer' }
    },
    {
        path: '/profile', 
        name: "Profile",
        component: () => import('../views/profile.vue'),
        meta: { title: 'SM Developer' }
    },
    {
        path: '/topup', 
        name: "Topup",
        component: () => import('../views/topup.vue'),
        meta: { title: 'SM Developer' }
    },
    {
        path: '/cart', 
        name: "Cart",
        component: () => import('../views/cart.vue'),
        meta: { title: 'SM Developer' }
    },
    {
        path: '/about', 
        name: "About",
        component: () => import('../views/about.vue'),
        meta: { title: 'SM Developer' }
    },
    { path: '/:pathMatch(.*)*', component: NotFoundComponent, meta: { title: 'SM Developer' } }
]

const router = new createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    document.title = 'SM Developer';
    next();
});


export default router
