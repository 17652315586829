<template>
    <div class="flex flex-col min-h-screen">
        <div class="flex-1 bg-white items-center justify-center h-full min-w-full">     
            <div class="flex items-center h-full p-16 dark:bg-gray-900 dark:text-gray-100 min-w-full">
                <div class="container flex flex-col items-center justify-center px-5 mx-auto my-8">
                    <div class="max-w-md text-center">
                        <h2 class="mb-8 font-extrabold text-9xl dark:text-gray-600">
                            <span class="sr-only">Error</span>404
                        </h2>
                        <p class="text-2xl font-semibold md:text-3xl">Sorry, we couldn't find this page.</p>
                        <p class="mt-4 mb-8 dark:text-gray-400">But dont worry, you can find plenty of other things on our homepage.</p>
                        <a rel="noopener noreferrer" href="#" class="px-8 py-3 font-semibold rounded btn ">Back to homepage</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="container mx-auto text-center py-6 select-none" >
            <p class="colorWhite">© 2022 SM Developer™. All Rights Reserved.</p>
        </div>
    </div>
</template>
<script>
/* import loadingScreen from '../components/loadingScreen.vue' */
export default {
    name: "pageNotfound",
    /* components: {
        loadingScreen
    }, */
};
</script>
